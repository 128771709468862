
import { defineComponent, onMounted, watch, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ApiAuth } from "@/core/api";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";

interface User {
  id: number;
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  language: string;
  advanced_manage: number;
}

export default defineComponent({
  name: "user-detail",
  components: {},
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const userInfo = ref<User>();
    userInfo.value = store.getters.currentUser;

    const formData = ref({
      username: "",
      email: "",
      password: "",
      firstname: "",
      lastname: "",
      mobile: "",
      telephone: "",
      address: "",
      postcode: "",
      city: "",
      country_iso_2: "",
      language: "en_US",
    });

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
      }
    );

    const getUserData = () => {
      ApiAuth.getUserInfo({ id: userInfo.value?.id })
        .then(({ data }) => {
          if (data.code == 0) {
            formData.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      getUserData();
    });

    return {
      t,
      formData,
      userInfo,
    };
  },
});
